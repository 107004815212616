






































































































































































































































import { Component } from 'vue-property-decorator';
import MixinMain from '@/mixing/MixinMain.vue';
import { IAudioData } from '@/model/agent/IAudioData';
import AppContainerBase from '@/components/util/AppContainerBase.vue';
import Util from '@/utils/Util';
import { internet } from '@/utils/Internet';
import { IHeaderTable } from '@/model/main/IHeaderTable';
import { ITranscripData } from '@/model/transcription/ITranscripData';
import EvaluationDataTable from '@/components/evaluation/EvaluationDataTable.vue';
import { Icon } from '@/model/util/Icon';
import { ISendData } from '@/model/evaluation/ISendData';

import { IDataTable } from '@/model/main/IDataTable';
@Component({
	name: 'MainEvaluation.vue',
	components: {
		AppContainerBase,
		EvaluationDataTable,
	},
})
export default class MainEvaluation extends MixinMain {
	public isLoading = false;
	public selected = '';
	public rows: Array<IDataTable> = [];
	public data: Array<ITranscripData> = [];
	public selectedAudio = '';
	public upperTitle = this.$t("transcriptionText.upperTitle");
	public title = this.$t("transcriptionText.title");
	public subtitle = this.$t("transcriptionText.subtitle");
	public cardTitle = this.$t("transcriptionText.cardTitle");
	public primaryTitle = this.$t("transcriptionText.primaryTitle");
	public backToText = this.$t("transcriptionText.textBackTo");
	public saveText = this.$t("transcriptionText.textSave");
	public items = [
		this.$t("transcriptionText.item0"),
		this.$t("transcriptionText.item1"),
		this.$t("transcriptionText.item2"),
		this.$t("transcriptionText.item3"),
		this.$t("transcriptionText.item4"),
		this.$t("transcriptionText.item5"),
		this.$t("transcriptionText.item6"),
		this.$t("transcriptionText.item7"),
	];
	public agent: Array<string> = [];
	public customer: Array<string> = [];
	public tas = 0;
	public nameAgent = '';
	public final!: ITranscripData;
	public audio_path = "";
	public token = localStorage.getItem('TOKEN')
	public header: Array<IHeaderTable<IDataTable>> = [];
	public evaluator = '';
	mounted() {
		this.evaluator = this.$store.state.storeLogin.user.name;
		if (localStorage.getItem('filter') === null) {
			this.$router.push({
				name: 'MainTranscription',
			});
		} else {
			this.data = JSON.parse(
				localStorage.getItem('filter') as string
			) as Array<ITranscripData>;
			this.selectedAudio = JSON.parse(
				localStorage.getItem('audio') as string
			) as string;
			const final = this.data.find(
				(e) => e.AudioNombre === this.selectedAudio
			);
			this.final = final as ITranscripData;
			this.nameAgent = final?.MT_Ejecutivo as string;
			this.customer = final?.TranscritoCliente.split(
				'#'
			) as Array<string>;
			this.agent = final?.TranscritoEjecutivo.split(
				'#'
			) as Array<string>;
			this.audio_path = process.env.VUE_APP_API;
			this.getData();
		}
		
	}

	
	public get isDisabled(): boolean {
		let exit = false;
		if (this.selectedAudio === '') exit = true;
		return exit;
	}

	private getData(): void {
		//console.log("final", this.final)
		this.isLoading = true;
		const request_1 = internet
			.newRequest()
			.get(`xentric/agente/audiosScript?FiltrAgent=${this.nameAgent}&${this.getFilterUrl}`);

		Util.waitForPromises([request_1])
			.then((responses) => {
				const data: Array<IAudioData> = responses[0]
					.data as Array<IAudioData>;
				const filter: Array<IAudioData> = data.filter(
					(e) => {
						return (
							// e.agente ===
							// 	this.final.MT_Ejecutivo &&
							// e.campania ===
							// 	this.final
							// 		.Campania &&
							e.audio ===
								this.final.AudioNombre 
						);			
					}
				);
				//console.log("filter", filter)
				this.final.AudioRuta = data[0].AudioRuta;
				const active: number = filter.filter(
					(e) => e.encontrado === 1
				).length;
				this.tas = parseFloat(
					((active * 100) / filter.length).toFixed(1)
				);

				const dataTable: Array<IDataTable> = [];
				for (let [index, item] of filter.entries()) {
					const row: IDataTable = {};
					row['id'] = index + 1;
					row['cat'] = item.categoria;
					row['sub'] = item.subcategoria;
					row['robot'] = item.encontrado;
					row['eval'] = '';
					row['tun'] = '';
					row['extra'] = '';
					row['active'] = false;
					row['reg'] = item._id;
					row['nro'] = item.MT_Folio;
					row['scr'] = item.IDScript;
					dataTable.push(row);
				}
				const header: Array<IHeaderTable<
					IDataTable
				>> = Object.keys(dataTable[0]).map(
					(
						key: string
					): IHeaderTable<IDataTable> => {
						let text = key;
						switch (key) {
							case 'id':
								text = '#';
								break;
							case 'cat':
								text = this.$t("transcriptionText.tableCat") as string;
								break;
							case 'sub':
								text = this.$t("transcriptionText.tableSubcat") as string;
								break;
							case 'robot':
								text = this.$t("transcriptionText.tableBot") as string;
								break;
							case 'eval':
								text = this.$t("transcriptionText.tableEval") as string;
								break;
							case 'tun':
								text = this.$t("transcriptionText.tableTun") as string;
								break;
						}
						return {
							text,
							value: key,
						};
					}
				) as Array<IHeaderTable<IDataTable>>;
				this.rows = dataTable;
				this.header = header;
			})
			.catch(console.log)
			.finally(() => {
				this.isLoading = false;
			});
	}
	public listenSelectedItem(payload: IDataTable) {
		this.selectedAudio = payload.item.audio;
	}

	public save() {
		this.isLoading = true;
		let error = false;
		const send: Array<ISendData> = [];
		for (let row of this.rows) {
			let data: ISendData = {};
			data['NRO_evaluacion'] = row.nro;
			data['agente'] = this.final.MT_Ejecutivo;
			data['audio'] = this.final.AudioNombre;
			data['categoria'] = row.cat;
			data['cliente'] = this.final.Cliente;
			data['codigo_script'] = row.scr;
			data['evaluacion_robot'] = row.robot;
			if (row.eval === '') {
				continue;
			}
			data['evaluacion_usuario'] = row.eval;
			data[
				'fecha_evaluacion'
			] = new Date().toLocaleString();
			data['fecha_llamada'] = "";
			data['id_registro'] = row.reg;
			data['revision_evaluacion'] = 'pendiente';
			data['subcategoria'] = row.sub;
			data['tas'] = this.tas.toFixed(1);
			data['tipo_llamada'] = this.final.MT_TipoLlamadaNombre;
			if (row.extra === '') {
				error = true;
				break;
			}
			data['tunning'] = `${row.tun} ${row.extra}`;
			data['unNegocio'] = this.final.Campania;
			data['usuario_evaluador'] = this.evaluator;
			send.push(data);
		}
		console.log(error, send);

		if (error) {
			Util.showMessage(
				this.$t("DataTable.warningTunning") as string,
				Icon.INFO,
				this.$t("DataTable.cuidado") as string
			);
			this.isLoading = false;
			return;
		}
		const requests = [];
		for (let reg of send) {
			const request = internet
				.newRequest()
				.post('insertEvaluacion/agente', reg);
			requests.push(request);
		}
		Util.waitForPromises(requests)
			.then((responses) => {
				Util.showMessage(
					this.$t("DataTable.cambiosGuardados") as string,
					Icon.SUCCESS,
					this.$t("DataTable.avisoExito") as string,
				);
			})
			.catch(console.log)
			.finally(() => {
				this.isLoading = false;
			});
	}


	public back() {
		this.$router.push({
			name: 'SelectTranscription',
		});
	}
}
